<template>
  <div class="kaidanHome">
    <SettlementCenter v-if="!isshow"></SettlementCenter>
    <KDjs v-if="isshow"></KDjs>
  </div>
</template>

<script>
export default {
  name: "kaidanHome",
  data() {
    return {
      isshow: 0
    }
  },
  created() {
    let arrs = this.$store.state.users.user.Company.ListCompanyConfig
    if (!arrs.some(item => item.BCKey == 'ERPSource')) {
      this.isshow = 1
    }
  },
  components: {
    SettlementCenter: () => import('@/views/kaidan/settlementCenter'),
    KDjs: () => import('@/views/kaidan/kdjs'),
  }
}
</script>

<style scoped>
.kaidanHome {
  width: 100vw;
  height: 76vh;
  overflow: hidden;
  overflow-y: auto;
}
</style>
